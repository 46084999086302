.App .licenses{
    max-height: 150px;
    overflow-y: scroll;
    scroll-behavior: auto;
    border: 1px solid lightgray;
}

.App a{
    color: whitesmoke;
    font-weight: bold;
}
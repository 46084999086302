body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #000;
  background: #00acc1;
  font-family: Asap, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #00acc1;
  color: #fff;
}

main {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

.center {
  margin-top: 50px
}

.centertext {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 24pt;
  text-align: center
}

.tagline {
  font-weight: 200;
  font-size: 12pt
}

.center {
  display: block;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 50%
}

.footerCopyright {
  font-weight: bold;
  font-size: 1em;
}

.footerPowered {
  font-weight: bold;
  font-size: 1em;
  height: 35px;

  font-size: 0.85em;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  height: 100%;

  -webkit-mask: url("/img/eventbug_icon_white.svg") no-repeat center;
  mask: url("/img/eventbug_icon_white.svg") no-repeat center;
}

.messageText {
  font-weight: bold;
  font-size: 1em;
}

.footerLink {
  font-weight: 100;
  font-size: 1em;
}